import React, { FC } from 'react'

import { ALBUM_IMAGES } from 'src/Constants/constants'
import { SpotifyTrack } from 'src/Types'
import spotifyIcon from 'src/Assets/Png/spotify-icon.png'

import { TrackAudioPlayer } from '../TrackAudioPlayer'
import { TrackCoverWrapper } from '../TrackAudioPlayer/TrackCoverWrapper'

import { CheckTrackFromSearchProps } from './CheckTrackFromSearchProps'

import {
  SearchResultsItem,
  SearchResultsImg,
  SearchResultsText,
  SearchResultsTitle,
  SearchResultsDescription,
} from './styles'

type SearchResultSpotifyItemProps = Pick<
  CheckTrackFromSearchProps,
  'onCheckTrack' | 'tracks'
>

export const SearchResultSpotifyItem: FC<SearchResultSpotifyItemProps> = (
  props,
) => {
  const { tracks, onCheckTrack } = props
  return (
    <TrackAudioPlayer
      render={({ isPlaying, loading, onClick, currentTrackUrl }) => (
        <>
          {((tracks as SpotifyTrack[]) || []).map((item) => (
            <SearchResultsItem
              key={item.id}
              onClick={() => onCheckTrack(item.id)}
            >
              <TrackCoverWrapper
                show={Boolean(item.preview_url)}
                isPlaying={isPlaying && currentTrackUrl === item.preview_url}
                loading={loading && currentTrackUrl === item.preview_url}
                onClick={(e) => {
                  onClick(e, item.preview_url as string)
                }}
              >
                <SearchResultsImg
                  src={item.album.images[ALBUM_IMAGES]?.url}
                  fallbackSrc={spotifyIcon}
                  alt={item.name}
                  width='40'
                />
              </TrackCoverWrapper>

              <SearchResultsText>
                <SearchResultsTitle>{item && item.name}</SearchResultsTitle>
                <SearchResultsDescription>
                  {item && item.artists[0].name}
                </SearchResultsDescription>
              </SearchResultsText>
            </SearchResultsItem>
          ))}
        </>
      )}
    />
  )
}
