import { FunctionComponent, useRef, useEffect } from 'react'

import { Input, InputRef } from 'antd'

import { useOutsideTouchHandle } from '../../../Hooks/useOutsideTouchHandle'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import SearchIcon from 'src/Assets/Svg/search.svg?react'

import { InputProps } from './InputProps'

import { CommonInputWrapper } from './styles'

const SearchInput: FunctionComponent<InputProps> = (props) => {
  const { autoFocus, withborder, withSearchIcon, show } = props
  const inputRef = useRef<InputRef>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [inputRef, autoFocus])

  const handleOutsideToutch = (): void => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }
  useOutsideTouchHandle({ ref: wrapperRef, callback: handleOutsideToutch })

  return (
    <CommonInputWrapper
      ref={wrapperRef}
      withborder={withborder}
      withSearchIcon={withSearchIcon}
      show={show}
    >
      {withSearchIcon && !show && <SearchIcon width={16} height={16} />}

      <Input
        ref={inputRef}
        {...props}
        autoComplete='off'
        allowClear={{ clearIcon: <CloseCross height={10} /> }}
      />
    </CommonInputWrapper>
  )
}

export default SearchInput
