import styled from 'styled-components'

import { colors, device } from 'src/Styled/variables'

export const StyledPageContainer = styled.div<{
  isMenuOpened?: boolean
  isAlertMode?: boolean
}>`
  box-sizing: content-box;
  min-height: calc(100vh - 30px);
  transition: padding-left 1s ease-out;

  padding-bottom: 16px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
  padding-left: 16px;
  padding-top: ${({ isAlertMode }) => (isAlertMode ? '175px' : '87px')};
  padding-right: 16px;
  background-color: ${colors.greyBlue};

  @media ${device.tabletMin} {
    padding-left: 24px;
    padding-top: ${({ isAlertMode }) => (isAlertMode ? '120px' : '87px')};
    padding-right: 24px;
    background-color: ${colors.greyBlue};
  }

  @media ${device.smallScreen} {
    padding-top: ${({ isAlertMode }) => (isAlertMode ? '137px' : '89px')};
    padding-left: ${({ isMenuOpened }) => (isMenuOpened ? '280px' : '112px')};
    padding-right: 40px;
    max-width: 1120px;
    margin: 0 auto;
  }
`
export const InnerWrapper = styled.div`
  box-sizing: border-box;
`
export const AlertWrapper = styled.div`
  position: absolute;
  top: 60px;
`
