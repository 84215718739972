import { FC } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PlusIcon from 'src/Assets/Svg/plus.svg?react'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { capitalizeFirstLetter } from 'src/Helpers/layoutHelpers'
import { ProperGenreOrLanguage } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'

import { ModalType } from '../../ModalsContainer'

import { useScheduleGenres } from './useScheduleGenres'

import {
  LanguagesGenresContainer,
  LanguagesGenresList,
  MatchingError,
  ParametersButton,
  SubTitle,
} from './styles'

interface ScheduleGenresProps {
  genres: ProperGenreOrLanguage[]
}
export const ScheduleGenres: FC<ScheduleGenresProps> = ({ genres }) => {
  const { t } = useTranslation()
  const { setSearch } = useCustomSearchParams()
  const { number: campaignId } = useParams()
  // const budgetRangeError = useAppSelector(getBudgetRangeError)
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })

  useScheduleGenres()

  const handleClick = (): void => {
    const query = {
      step: ScheduleSteps.SETTINGS,
      modal: ModalType.Genres,
    }
    setSearch(query)
  }

  if (!genres.length) {
    return (
      <ParametersButton onClick={handleClick}>
        <PlusIcon />
        {t('scheduleCampaignPage.addTrackGenres')}
      </ParametersButton>
    )
  }
  const genresString = genres
    .map((genre) => capitalizeFirstLetter(genre.name || ''))
    .join(', ')

  return (
    <LanguagesGenresContainer>
      <div>
        <SubTitle>{t('scheduleCampaignPage.trackGenres')}</SubTitle>
        <LanguagesGenresList translate='no'>{genresString}</LanguagesGenresList>
        {budgetRangeError && (
          <MatchingError>
            {t('scheduleCampaignPage.trackGenresError')}
          </MatchingError>
        )}
      </div>
      <ParametersButton onClick={handleClick}>
        {t('scheduleCampaignPage.edit')}
      </ParametersButton>
    </LanguagesGenresContainer>
  )
}
