import { createSlice } from '@reduxjs/toolkit'

import { ApiError, NameSpace, SliceStatus } from 'src/Redux/types'
import { setApiError } from 'src/Hooks/redux'

import { Feature, addFeatureForUser, getUserFeatures } from './api-actions'

export interface FeaturesState {
  features: Array<Feature>
  memorizedFeatures: Array<Feature>
  getFeaturesStatus: SliceStatus
  addFeatureForUserStatus: SliceStatus
  error: ApiError | null
}
const initialState: FeaturesState = {
  features: [],
  memorizedFeatures: [],
  getFeaturesStatus: SliceStatus.Idle,
  addFeatureForUserStatus: SliceStatus.Idle,
  error: null,
}

export const featuresSlice = createSlice({
  initialState,
  name: NameSpace.Features,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserFeatures.pending, (state) => {
      state.getFeaturesStatus = SliceStatus.Loading
    })
    builder.addCase(getUserFeatures.fulfilled, (state, { payload }) => {
      state.getFeaturesStatus = SliceStatus.Succeeded
      state.features = payload.features
      state.error = null
    })
    builder.addCase(getUserFeatures.rejected, (state, action) => {
      state.getFeaturesStatus = SliceStatus.Failed
      state.error = setApiError(action)
    })

    // addFeatureForUser
    builder.addCase(addFeatureForUser.pending, (state) => {
      state.addFeatureForUserStatus = SliceStatus.Loading
    })
    builder.addCase(addFeatureForUser.fulfilled, (state) => {
      state.addFeatureForUserStatus = SliceStatus.Succeeded
      state.error = null
    })
    builder.addCase(addFeatureForUser.rejected, (state, action) => {
      state.addFeatureForUserStatus = SliceStatus.Failed
      state.error = setApiError(action)
    })
  },
})
