import React, { FC } from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Alert from 'src/Components/Alert'
import {
  getIsRecipient,
  getTransactionsError,
} from 'src/Redux/transactions-process/selectors'
import { DottedBtn } from 'src/Components/DottedBtn/DottedBtn'
import { useAppSelector } from 'src/Hooks/redux'
import { getPayoutError } from 'src/Redux/transactions-process/orderPayoutSlice/selectors'

import { AlertText, AlertTitle, WrapperWithButton } from './styles'

export const BalanceAlerts: FC = () => {
  const { t } = useTranslation()
  const transactionsError = useAppSelector(getTransactionsError)
  const payoutError = useAppSelector(getPayoutError)
  const recipient = useSelector(getIsRecipient)
  const navigate = useNavigate()

  const handleAddBankDetails = (): void => {
    navigate('/settings')
  }

  if (transactionsError || payoutError) {
    return (
      <Alert unclosable color='danger' icon>
        <WrapperWithButton>
          <AlertText>{t('balancePage.Oops')}</AlertText>
        </WrapperWithButton>
      </Alert>
    )
  }
  if (!recipient && recipient !== null) {
    return (
      <Alert unclosable color='warning' icon>
        <WrapperWithButton>
          <AlertText>
            <AlertTitle>{t('balancePage.setUpBankDetails')}</AlertTitle>
            <div>{t('balancePage.onceYouHave')}</div>
          </AlertText>
          <DottedBtn
            onClick={handleAddBankDetails}
            content='Add bank details'
          />
        </WrapperWithButton>
      </Alert>
    )
  }
  return null
}
