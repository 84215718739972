import React, { FC } from 'react'

import CloseCross from 'src/Assets/Svg/close-cross.svg?react'
import ArrowLeft from 'src/Assets/Svg/arrow-left.svg?react'
import SearchInput from 'src/Containers/ui/Input'

import { SearchInputWrapper, SmallButton } from './styles'
interface Props {
  placeholder: string
  value: string
  setValue: (value: string) => void
  onClose: () => void
}

export const MobileSearchInput: FC<Props> = ({
  value,
  setValue,
  onClose,
  placeholder,
}) => {
  const handleClearInput = (): void => {
    setValue('')
  }
  return (
    <SearchInputWrapper>
      <div>
        <SmallButton onClick={onClose}>
          <ArrowLeft data-testid='closeButton' />
        </SmallButton>
      </div>

      <SearchInput
        required
        autoFocus
        id='searchInput'
        name='searchInput'
        type='text'
        value={value}
        placeholder={placeholder}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setValue(e.target.value)
        }
      />
      {value && (
        <div>
          <SmallButton onClick={handleClearInput}>
            <CloseCross data-testid='clearButton' fill='' />
          </SmallButton>
        </div>
      )}
    </SearchInputWrapper>
  )
}
