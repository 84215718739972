import { useCallback, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAppSelector } from 'src/Hooks/redux'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { getHasActiveSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { subscription } from 'src/Router/routes'
import { PaymentMethodEnum } from 'src/Constants/enums'
import {
  useCreateBillingAgreementMutation,
  useGetBillingAgreementQuery,
} from 'src/Redux/billingAgreementApi'

import { useCreateSubscription } from './useCreateSubscription'
import { useChangeSubscription } from './useChangeSubscription'

interface UsePaySubscription {
  onPayPaypal: () => void
  hasBilling: boolean
  email?: string
}

export const usePayPaypalSubscription = (): UsePaySubscription => {
  const navigate = useNavigate()
  const { search } = useCustomSearchParams()
  const hasActiveSubscription = useAppSelector(getHasActiveSubscriptionSelector)
  const { data: billingData, isSuccess: isFetchingBillingAgreement } =
    useGetBillingAgreementQuery()
  const [createBillingAgreement] = useCreateBillingAgreementMutation()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = search.get('plan')
  const autopay = search.get('autopay')
  const paymentType = search.get('paymentType')

  const [create] = useCreateSubscription()
  const [change] = useChangeSubscription()

  const onPayPaypal = useCallback((): void => {
    if (!packageId || !price || !plan) {
      return
    }
    if (!billingData?.id) {
      createBillingAgreement({
        cancelUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&cashback=${cashback}&plan=${plan}&paymentType=${PaymentMethodEnum.PAYPAL}&error=save_token_error`,
        successUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&cashback=${cashback}&plan=${plan}&paymentType=${PaymentMethodEnum.PAYPAL}&autopay=true`,
      })
        .then((res) => {
          if (res.data?.link) {
            window.location.href = res.data?.link
          }
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${PaymentMethodEnum.PAYPAL}&error=subscription_error`,
          )
        })
      return
    }

    if (hasActiveSubscription) {
      change(PaymentMethodEnum.PAYPAL, billingData?.id)
    } else {
      create(PaymentMethodEnum.PAYPAL, billingData?.id)
    }
  }, [
    cashback,
    change,
    create,
    hasActiveSubscription,
    navigate,
    packageId,
    plan,
    price,
    createBillingAgreement,
    billingData?.id,
  ])
  const isOnceCalled = useRef<boolean>(false)

  useEffect(() => {
    if (
      autopay &&
      paymentType === PaymentMethodEnum.PAYPAL &&
      isFetchingBillingAgreement &&
      !isOnceCalled.current
    ) {
      onPayPaypal()
      isOnceCalled.current = true
    }
  }, [autopay, isFetchingBillingAgreement, onPayPaypal, paymentType])
  return {
    onPayPaypal,
    hasBilling: Boolean(billingData?.id),
    email: billingData?.paypalEmail,
  }
}
