import { useState, useEffect } from 'react'

export const useScript = (
  src: string,
): {
  loaded: boolean
  error: boolean
} => {
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = src
    script.defer = true

    const onLoad = (): void => setLoaded(true)
    const onError = (): void => setError(true)

    script.addEventListener('load', onLoad)
    script.addEventListener('error', onError)

    document.body.appendChild(script)

    return () => {
      script.removeEventListener('load', onLoad)
      script.removeEventListener('error', onError)
      document.body.removeChild(script)
    }
  }, [src])

  return { loaded, error }
}
