import { useCallback, useEffect, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import {
  getCardInfo,
  getStripeSetupIntent,
  useGetSavedCardsQuery,
} from 'src/Redux/savedCardsApi'
import { useCreateSetupIntentMutation } from 'src/Redux/setupIntentApi'
import { subscription } from 'src/Router/routes'
import { getHasActiveSubscriptionSelector } from 'src/Redux/subscription-process/subscription/selectors'
import { useAppSelector } from 'src/Hooks/redux'
import { PaymentMethodEnum } from 'src/Constants/enums'

import { useCreateSubscription } from './useCreateSubscription'
import { useChangeSubscription } from './useChangeSubscription'

interface UseStripeIntent {
  onPayStripe: () => Promise<void>
  hasIntent: boolean
  cardInfo?: string
}

export const usePayStripeSubscription = (): UseStripeIntent => {
  const { data, isSuccess } = useGetSavedCardsQuery()
  const navigate = useNavigate()
  const setupIntent = getStripeSetupIntent(data)
  const cardInfo = getCardInfo(data)
  const hasActiveSubscription = useAppSelector(getHasActiveSubscriptionSelector)
  const [create] = useCreateSubscription()
  const [change] = useChangeSubscription()
  const [createSetupIntent] = useCreateSetupIntentMutation()
  const { search } = useCustomSearchParams()
  const packageId = Number(search.get('packageId'))
  const price = Number(search.get('price'))
  const cashback = Number(search.get('cashback'))
  const plan = String(search.get('plan'))
  const autopay = search.get('autopay')
  const paymentType = search.get('paymentType')

  const onPayStripe = useCallback(async (): Promise<void> => {
    if (!packageId || !price || !plan) {
      return
    }
    if (!setupIntent?.id) {
      await createSetupIntent({
        successUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&plan=${plan}&cashback=${cashback}&paymentType=${PaymentMethodEnum.STRIPE}&autopay=true`,
        cancelUrl: `${window.location.origin}${subscription}/pay?packageId=${packageId}&price=${price}&plan=${plan}&cashback=${cashback}&paymentType=${PaymentMethodEnum.STRIPE}&error=save_token_error`,
      })
        .unwrap()
        .then((res) => {
          window.location.href = res.link
          return res
        })
        .catch(() => {
          navigate(
            `${subscription}/pay?packageId=${packageId}&plan=${plan}&price=${price}&paymentType=${PaymentMethodEnum.STRIPE}&error=subscription_error`,
          )
        })
      return
    }
    if (hasActiveSubscription) {
      change(PaymentMethodEnum.STRIPE, setupIntent?.id)
    } else {
      create(PaymentMethodEnum.STRIPE, setupIntent?.id)
    }
  }, [
    cashback,
    change,
    create,
    createSetupIntent,
    hasActiveSubscription,
    navigate,
    packageId,
    plan,
    price,
    setupIntent?.id,
  ])
  const isOnceCalled = useRef<boolean>(false)
  useEffect(() => {
    if (
      autopay &&
      paymentType === PaymentMethodEnum.STRIPE &&
      isSuccess &&
      !isOnceCalled.current
    ) {
      onPayStripe()
      isOnceCalled.current = true
    }
  }, [autopay, isSuccess, onPayStripe, paymentType])

  return { cardInfo, onPayStripe, hasIntent: Boolean(setupIntent) }
}
