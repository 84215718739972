import React, { ReactElement, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { ViewsProgressChart } from 'src/Components/ViewsProgressChart'
import TikTokIcon from 'src/Assets/Svg/tiktok.svg?react'
import { useSubmittedSoundByIdQuery } from 'src/Redux/creatorSubmittedSoundsApi'
import { sounds } from 'src/Router/routes'
import dayjs from 'src/Helpers/dayjs'
import { formatDate } from 'src/Constants/formatDate'
import { addSpaceToNumber } from 'src/Helpers/layoutHelpers'

import { WarningModal } from '../../../WarningModal'
import { DetailsTitle } from '../DetailsTitle'
import { Player } from '../Player'
import { FollowInfo } from '../FollowInfo'
import { Description, InfoWrapper, Title } from '../Range'
import { Container, Content, RangeWrapper, StyledButton } from '../../styles'
import { TabKeysEnum } from '../../../../types'
import { SoundStatus, useSoundStatus } from '../../../../hooks/useSoundStatus'

import { Alerts } from './components/Alerts'
import { useAlerts } from './components/Alerts/useAlerts'

const errorModalLink = `${sounds}/${TabKeysEnum.IN_PROGRESS}?errorModalVisible=true`

export const SubmittedSoundDetails = (): ReactElement | null => {
  const { t } = useTranslation()
  const { soundId } = useParams<{ soundId: string }>()
  const navigate = useNavigate()
  const { getStatusValues } = useSoundStatus()
  const { search } = useCustomSearchParams()

  const { isTaskNotCompletedAlert } = useAlerts()

  const { data: soundData, error: isDefaultErrorAlert } =
    useSubmittedSoundByIdQuery(Number(soundId))

  useEffect(() => {
    if (isDefaultErrorAlert) {
      navigate(errorModalLink)
    }
  }, [isDefaultErrorAlert, navigate])

  if (!soundData) {
    return null
  }

  const soundStatus = search.get('soundStatus')

  const isSoundNotAvailable = soundStatus === 'notAvailable'

  const isRangeHidden =
    isTaskNotCompletedAlert || soundData.status === SoundStatus.DECLINED

  const formattedMaxCreatorReward = `$${soundData.maxCreatorReward.toFixed(2)}`

  const { title: badgeTitle, type: badgeType } = getStatusValues(
    soundData.status,
    soundData.isPaymentReceived,
  )

  return (
    <Container>
      <DetailsTitle
        badgeTitle={badgeTitle}
        badgeType={badgeType}
        title={t('soundsPage.submittedModalTitle')}
      />

      <Alerts />

      <Content>
        <Player
          trackAuthorName={soundData.trackAuthorName}
          trackPlayUrl={soundData.trackPlayUrl}
          trackTitle={soundData.trackTitle}
          trackCoverUrl={soundData.trackCoverUrl}
          trackUrl={soundData.trackTiktokUrl}
        />

        <FollowInfo comment={soundData?.comment} />

        {!isRangeHidden && (
          <RangeWrapper>
            <InfoWrapper>
              <Title>
                {t('soundsPage.earnUpTo')} {formattedMaxCreatorReward}
              </Title>
              <Description>
                {t('soundsPage.rewardedDescription', {
                  minViews: addSpaceToNumber(soundData.minViews, ','),
                  milestoneDay: dayjs(soundData?.viewsMilestoneDate).format(
                    formatDate.formatDateMonthFull,
                  ),
                })}
              </Description>
            </InfoWrapper>

            <ViewsProgressChart
              min={{
                price: soundData.minCreatorReward,
                views: soundData.minViews,
              }}
              max={{
                price: soundData.maxCreatorReward,
                views: soundData.maxViews,
              }}
              value={{ price: soundData.reward, views: soundData.views }}
              completed={soundData.isMilestoneDaysPassed !== null}
            />
          </RangeWrapper>
        )}

        <Link to={soundData.videoLink}>
          <StyledButton type={'whiteWithGreenBorder'}>
            <TikTokIcon width={14} height={16} />
            <span>{t('soundsPage.videoOnTikTok')}</span>
          </StyledButton>
        </Link>
      </Content>
      {isSoundNotAvailable && <WarningModal />}
    </Container>
  )
}
