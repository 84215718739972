import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Alert from 'src/Components/Alert'
import { useAppSelector } from 'src/Hooks/redux'
import { ErrorCode } from 'src/Redux/types'
import { getVideoRateError } from 'src/Redux/campaignVideo-process/videoRate-process/selectors'

export const TiktokRateError: FC = () => {
  const error = useAppSelector(getVideoRateError)
  const { t } = useTranslation()

  if (error?.code === ErrorCode.Conflict) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <div>{t('campaignResultsPage.videoAlreadyRated')}</div>
      </Alert>
    )
  }
  if (error?.code === ErrorCode.InternalServerError) {
    return (
      <Alert smallGap color='danger' icon unclosable>
        <div>{t('campaignResultsPage.oops')}</div>
      </Alert>
    )
  }
  return null
}
