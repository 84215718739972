import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import Button from 'src/Components/Buttons/Button'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { usePaymentAmountsWithCurrency } from 'src/Hooks/usePaymentAnountsWithCurrency'
import { useCheckoutButton } from 'src/Hooks/useCheckoutButton'

import {
  CheckoutMobileFooterButtonsContainer,
  TotalContainer,
  TotalLabel,
  TotalSum,
} from './styles'

export const CheckoutMobileFooter: FC<{
  platformType: CampaignPlatformTypeEnum
}> = ({ platformType }) => {
  const { isLoading, buttonType, text, onClickContinue } =
    useCheckoutButton(platformType)
  const { currencyTotal } = usePaymentAmountsWithCurrency(platformType)
  const isMobile = useMediaQuery({ maxWidth: 767 })

  if (!isMobile) {
    return null
  }

  return (
    <CheckoutMobileFooterButtonsContainer>
      <TotalContainer>
        <TotalLabel>Total</TotalLabel>
        <TotalSum>{currencyTotal}</TotalSum>
      </TotalContainer>

      <Button type={buttonType} loading={isLoading} onClick={onClickContinue}>
        {text}
      </Button>
    </CheckoutMobileFooterButtonsContainer>
  )
}
