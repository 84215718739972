import { useNavigate } from 'react-router-dom'

import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { getCookieByName, getUtmParameters } from 'src/Constants/functions'
import { useCreateCampaignEvents } from 'src/Hooks/Analytics/useCreateCampaignEvents'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getAccessToken } from 'src/Redux/auth-process/credentialsSlice/selectors'
import { setIsFirstCampaign } from 'src/Redux/auth-process/userSlice'
import {
  useCreateNewSpotifyCampaignMutation,
  useCreateNewTiktokCampaignMutation,
} from 'src/Redux/createNewCampaignApi'
import { sendSourceBusterEvent } from 'src/Redux/sourcebuster-process/api-actions'
import { setTracksToInitialState } from 'src/Redux/spotifyTracks-process'
import {
  setTiktokTrackToInitialState,
  setTiktokTracksToInitialState,
} from 'src/Redux/tiktokTrack-process'
import { setTrackToInitialState } from 'src/Redux/track-process'
import {
  campaignsList,
  createCampaign,
  scheduleCampaign,
  scheduleCampaignTikTok,
} from 'src/Router/routes'
import {
  CampaignPlatformTypeEnum,
  CampaignStatusEnum,
  TikTokTrack,
  TiktokCampaignStatus,
  Track,
} from 'src/Types'

interface ICreateNewCampaign {
  createNewSpotifyCampaign: (track: Track) => void
  createNewTiktokCampaign: (track: TikTokTrack) => void
  loading: boolean
}

export const useCreateNewCampaign = (): ICreateNewCampaign => {
  const token = useAppSelector(getAccessToken)
  const [createSpotifyCampaign, { isLoading: isSpotifyLoading }] =
    useCreateNewSpotifyCampaignMutation()
  const [createTiktokCampaign, { isLoading: isTiktokLoading }] =
    useCreateNewTiktokCampaignMutation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    createCampaignEventHandler,
    createdTikTokCampaignEventHandler,
    createTiktokCampaignEventHandler,
    campaignDetailsEventHandler,
    createdSpotifyCampaignEventHandler,
  } = useCreateCampaignEvents()

  const sendSourceBusterData = (
    id: number,
    campaignPlatform: CampaignPlatformTypeEnum,
  ): void => {
    const utmObj = getUtmParameters(getCookieByName('soundCampaignUtm'))

    void dispatch(
      sendSourceBusterEvent({
        token,
        campaignPlatform,
        campaignId: id,
        eventName: 'create_basic_campaign',
        gclid: utmObj.gclid || '',
      }),
    )
  }

  const createNewSpotifyCampaign = async (
    spotifyTrack: Track,
  ): Promise<void> => {
    dispatch(setIsFirstCampaign(false))
    await createSpotifyCampaign({
      trackName: spotifyTrack?.name,
      artistUrl: spotifyTrack?.artists[0]?.external_urls?.spotify,
      trackImage: spotifyTrack?.album.images[0]?.url,
      trackLength: spotifyTrack?.duration_ms,
      artistName: spotifyTrack?.artists[0]?.name,
      artistSpotifyId: spotifyTrack?.artists[0]?.id,
      trackSpotifyId: String(spotifyTrack?.id),
      trackUrl: spotifyTrack?.href,
      pageUrl: window.location.href,
    })
      .unwrap()
      .then((response) => {
        createCampaignEventHandler({ campaignId: response.id })

        createdSpotifyCampaignEventHandler({
          campaignId: response.id,
          isFeedCampaign: response.is_feed,
          spotifyTrackId: response.Track.track_id,
        })

        campaignDetailsEventHandler({
          campaignType: response.type,
          platformType: CampaignPlatformTypeEnum.SPOTIFY,
        })

        sendSourceBusterData(response.id, CampaignPlatformTypeEnum.SPOTIFY)

        dispatch(setTracksToInitialState())

        if (response.status === CampaignStatusEnum.APPROVED) {
          navigate(
            `${campaignsList}${scheduleCampaign}/${response.id}?step=${ScheduleSteps.SETTINGS}`,
          )
        } else {
          navigate(
            `${campaignsList}${createCampaign}/${CampaignPlatformTypeEnum.SPOTIFY}`,
            { state: { successCreatedCampaign: true } },
          )
        }
        dispatch(setTrackToInitialState())
        return response
      })
  }

  const createNewTiktokCampaign = async (
    tikTokTrack: TikTokTrack,
  ): Promise<void> => {
    await createTiktokCampaign({
      initialVideoCount: tikTokTrack.initialVideoCount,
      trackAuthorName: tikTokTrack.trackAuthorName,
      trackCoverUrl: tikTokTrack.trackCoverUrl,
      trackDuration: tikTokTrack.trackDuration,
      trackPlayUrl: tikTokTrack.trackPlayUrl,
      trackTiktokId: tikTokTrack.trackTiktokId,
      trackTitle: tikTokTrack.trackTitle,
      trackUrl: tikTokTrack.trackUrl,
    })
      .unwrap()
      .then((response) => {
        createCampaignEventHandler({ campaignId: response.id })
        createdTikTokCampaignEventHandler({
          campaignId: response.id,
          trackTiktokId: response.trackTiktokId,
        })
        createTiktokCampaignEventHandler({ campaignId: response.id })
        campaignDetailsEventHandler({
          campaignType: response.type,
          platformType: CampaignPlatformTypeEnum.TIKTOK,
        })

        sendSourceBusterData(
          Number(response.id),
          CampaignPlatformTypeEnum.TIKTOK,
        )

        dispatch(setTiktokTracksToInitialState())
        dispatch(setTiktokTrackToInitialState())
        if (response.status === TiktokCampaignStatus.PENDING) {
          navigate(
            `${campaignsList}${scheduleCampaignTikTok}/${response.id}?step=${ScheduleSteps.SETTINGS}`,
          )
        } else {
          navigate(
            `${campaignsList}${createCampaign}/${CampaignPlatformTypeEnum.TIKTOK}`,
            { state: { successCreatedCampaign: true } },
          )
        }

        return response
      })
  }
  return {
    createNewSpotifyCampaign,
    createNewTiktokCampaign,
    loading: isSpotifyLoading || isTiktokLoading,
  }
}
