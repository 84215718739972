import { FunctionComponent } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Button from 'src/Components/Buttons/Button'
import { useAppSelector } from 'src/Hooks/redux'
import {
  getCampaignToSchedule,
  getIsUpdateCampaignLoading,
} from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { ScheduleSteps } from 'src/Components/ScheduleTabs'
import { useCheckoutEvents } from 'src/Hooks/useCheckoutEvents'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'

import { getIsLanguageActive } from '../helpers'

import { SettingsContinueButtonWrapper } from './styles'

export const SettingsContinueButton: FunctionComponent = () => {
  const { t } = useTranslation()
  const { handleCheckoutStartEvent } = useCheckoutEvents()
  const { number: campaignId } = useParams()

  const isUpdateCampaignLoading = useAppSelector(getIsUpdateCampaignLoading)
  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { isError: budgetRangeError } = useGetBudgetRangeQuery(campaignId, {
    skip: !campaignId,
  })

  const { setSearch } = useCustomSearchParams()
  const isLanguageActive = getIsLanguageActive(campaignDetails.languages)
  if (!campaignDetails.genres.length || isLanguageActive) {
    return null
  }
  const handleClick = (): void => {
    setSearch({ step: ScheduleSteps.CHECKOUT })

    handleCheckoutStartEvent({
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      id: Number(campaignDetails.id),
      campaignType: campaignDetails.type,
      isFeed: campaignDetails.is_feed,
      genres: campaignDetails.genres.map((genre) => genre.name),
      languages: campaignDetails.languages.map((language) => language.name),
      startDate: (campaignDetails.start_date || '').toString(),
      resultPrice: campaignDetails.price,
    })
  }
  const hasPrice = campaignDetails.price > 0
  const isDisabled = budgetRangeError || !hasPrice || isUpdateCampaignLoading

  return (
    <SettingsContinueButtonWrapper>
      <Button onClick={handleClick} disabled={isDisabled} type='green'>
        {t('scheduleCampaignPage.continue')}
      </Button>
    </SettingsContinueButtonWrapper>
  )
}
