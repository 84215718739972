import { FC } from 'react'

import { useSelector } from 'react-redux'

import avatar from 'src/Assets/Png/ava.png'
import { SmartImage } from 'src/Components/SmartImage'
import { getNotifications } from 'src/Redux/notification-process/selectors'
import { getIsArtist } from 'src/Redux/auth-process/credentialsSlice/selectors'

import { ArtistNotification } from '../ArtistNotification'
import { HaveNotNotifications } from '../HaveNotNotifications'
import { OtherNotification } from '../OtherNotification'

import {
  NotificationsItemCurator,
  ImageCurator,
  NotificationsWrapper,
} from './styles'

const MAX_DISPLAYED_NOTIFICATIONS = 4

export const TopBarNotificationContent: FC = () => {
  const isArtist = useSelector(getIsArtist)
  const notifications = useSelector(getNotifications)

  if (!notifications.length) {
    return <HaveNotNotifications />
  }

  const isSmallNotificationsContainer = notifications?.length
    ? notifications?.length < MAX_DISPLAYED_NOTIFICATIONS
    : false

  if (isArtist) {
    return (
      <NotificationsWrapper
        isSmallNotificationsContainer={isSmallNotificationsContainer}
      >
        {notifications.map(({ id, date, Playlist }) => (
          <ArtistNotification
            key={id}
            firstName={Playlist?.Curator?.first_name}
            lastName={Playlist?.Curator?.last_name}
            photo={Playlist?.Curator?.user_photo_url}
            date={date}
            playlistName={Playlist?.playlist_name}
            playlistUrl={Playlist?.playlist_url}
          />
        ))}
      </NotificationsWrapper>
    )
  }

  return (
    <NotificationsWrapper
      isSmallNotificationsContainer={isSmallNotificationsContainer}
    >
      {notifications.map((item) => (
        <NotificationsItemCurator key={item.id}>
          <ImageCurator>
            <SmartImage src={avatar} alt='avatar' fallbackSrc={avatar} />
          </ImageCurator>
          <OtherNotification {...item} />
        </NotificationsItemCurator>
      ))}
    </NotificationsWrapper>
  )
}
