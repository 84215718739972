import { createDraftSafeSelector } from '@reduxjs/toolkit'

import { RootState } from 'src/Redux'
import { AppSelector } from 'src/Hooks/redux'

import { SliceStatus } from '../types'

import { FeaturesState } from '.'

const getState = (state: RootState): FeaturesState => state.aBFeatures
export const getFeatures = createDraftSafeSelector(
  getState,
  (state) => state.features,
)

export const getFeaturesIsSucceeded = createDraftSafeSelector(
  getState,
  ({ getFeaturesStatus }) => getFeaturesStatus === SliceStatus.Succeeded,
)

export const getIsFeatureActive = (feature: string): AppSelector<boolean> =>
  createDraftSafeSelector(getState, ({ features }) =>
    features?.some((item) => item?.name === feature),
  )

export const getIsFeatureValue = (
  feature: string,
): AppSelector<number | undefined> =>
  createDraftSafeSelector(
    getState,
    ({ features }) =>
      features?.find((item) => item?.name === feature)?.variation,
  )
