import { FC } from 'react'

import { useTranslation } from 'react-i18next'

import Button from 'src/Components/Buttons/Button'
import { changeCampaign } from 'src/Redux/campaign-process/mainOldProcessSlice/api-actions'
import { useAppDispatch, useAppSelector } from 'src/Hooks/redux'
import { getCampaignToSchedule } from 'src/Redux/campaign-process/mainOldProcessSlice/selectors'
import { CampaignPlatformTypeEnum } from 'src/Types'
import { useGenresAndLanguagesAnalytics } from 'src/Hooks/useGenresAndLanguagesAnalytics'
import { ANY_LANGUAGE_ID } from 'src/Constants/constants'
import { useCustomSearchParams } from 'src/Hooks/useCustomSearchParams'
import { useLazyGetBudgetRangeQuery } from 'src/Redux/spotifyBudgetRangeApi'

import { FooterContainer } from '../styles'

import { SelectedLanguage } from '.'

interface Props {
  selectedLanguage: SelectedLanguage | null
  targetLanguage?: number
}

export const LanguagesModalFooter: FC<Props> = ({
  selectedLanguage,
  targetLanguage,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const [getCampaignBudgetRange] = useLazyGetBudgetRangeQuery()

  const { handleLanguageAddedEvent } = useGenresAndLanguagesAnalytics()

  const campaignDetails = useAppSelector(getCampaignToSchedule)
  const { setSearch } = useCustomSearchParams()

  const handleSave = (): void => {
    const languages =
      targetLanguage === 0
        ? [selectedLanguage?.value]
        : [selectedLanguage?.value, targetLanguage]

    handleLanguageAddedEvent({
      platformName: CampaignPlatformTypeEnum.SPOTIFY,
      languageId: String(selectedLanguage?.value),
      languageName: String(selectedLanguage?.label),
      isAnyLanguage: Boolean(languages.find((id) => id === ANY_LANGUAGE_ID)),
    })
    void dispatch(
      changeCampaign({
        campaign_id: campaignDetails?.id,
        languages_ids: languages,
        genres_ids: campaignDetails.genres.map((el) => Number(el.id)),
        moods_ids: null,
        max_price: null,
      }),
    ).then((res) => {
      setSearch({ modal: null })
      if (campaignDetails.genres.length) {
        getCampaignBudgetRange(String(campaignDetails?.id))
      }
      return res
    })
  }

  return (
    <FooterContainer translate='no'>
      <Button disabled={!selectedLanguage} onClick={handleSave} type='green'>
        {t('scheduleCampaignPage.addLanguage')}
      </Button>
    </FooterContainer>
  )
}
