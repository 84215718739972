import { FC, useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { useMediaQuery } from 'react-responsive'

import { getAllSearchErrorSelector } from 'src/Redux/tiktokTrack-process/selectors'
import { HowToGetYourTrackURLButton } from 'src/Components/HowToGetYourTrackURLButton'
import { TrackSearch } from 'src/Components/TrackSearch'
import { IconComponent } from 'src/Components/IconComponent'
import { FormDataProps } from 'src/Components/TrackSearch/TrackSearchProps'
import {
  SearchForm,
  StyledTrackSearch,
} from 'src/Components/TrackSearch/styles'
import { useTrackSearchListeners } from 'src/Components/TrackSearch/helpers/useTrackSearchListeners'
import { SearchDropdownMessages } from 'src/Components/TrackSearch/components/SearchDropdownMessages'
import { DropdownResult } from 'src/Components/TrackSearch/components/DropdownResult'
import { SearchInputBlock } from 'src/Components/TrackSearch/components/SearchInputBlock'
import { usePlaceholderText } from 'src/Components/TrackSearch/helpers/usePlaceholderText'

import { SearchTrackByPlatformParams } from '../index'

import { useTrackSearchForm } from './useTrackSearchForm'

export const TrackSearchForm: FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 567px)' })

  const [isShowFullscreenInput, setShowFullscreenInput] = useState(false)
  const searchError = useSelector(getAllSearchErrorSelector)
  const { platform } = useParams<SearchTrackByPlatformParams>()

  useTrackSearchListeners()

  const { trackSearchHandler, setTracksToInitialStateHandler } =
    useTrackSearchForm()
  const { placeholderText } = usePlaceholderText(platform)

  useEffect(
    () => () => {
      setShowFullscreenInput(false)
      setTracksToInitialStateHandler()
    },
    [setTracksToInitialStateHandler],
  )

  const { control, handleSubmit, setValue } = useForm<FormDataProps>({
    mode: 'onChange',
  })

  const clearInput = (): void => {
    setValue('searchInput', '')
  }

  const handleFormClick = (): void => {
    if (isMobile) {
      setShowFullscreenInput(true)
    }
  }

  const handleBackClick = (): void => {
    if (isMobile) {
      setShowFullscreenInput(false)
      clearInput()
    }
  }

  return (
    <StyledTrackSearch
      show={isShowFullscreenInput}
      isSame={Boolean(searchError?.includes('The link'))}
    >
      <SearchForm
        show={isShowFullscreenInput}
        isErrorOnMobile={Boolean(searchError)}
        onSubmit={handleSubmit(trackSearchHandler)}
      >
        <>
          <SearchInputBlock
            onFocus={handleFormClick}
            onClickBack={handleBackClick}
            placeholderText={placeholderText}
            clearInput={clearInput}
            control={control}
            isFAQ={isShowFullscreenInput}
            show={isShowFullscreenInput}
          />
          <DropdownResult
            setTracksToInitialStateHandler={setTracksToInitialStateHandler}
            platform={platform}
          />
        </>

        <SearchDropdownMessages />

        <HowToGetYourTrackURLButton platform={platform} />
      </SearchForm>
    </StyledTrackSearch>
  )
}
TrackSearch.displayName = 'TrackSearch'
