import styled from 'styled-components'

import { colors, device, variables } from 'src/Styled/variables'
import { CampaignBudgetSkeleton } from 'src/Containers/ui/CampaignBudgetSkeleton'

export const StyledCampaignBudgetSkeleton = styled(CampaignBudgetSkeleton)<{
  $withButton: boolean
}>`
  height: ${(props) => (props.$withButton ? '568px' : '480px')};
`

export const SettingsBudgetContainer = styled.div`
  display: flex;
  padding: 24px;
  flex-direction: column;
  gap: 24px;
  background-color: ${colors.white};
  border-radius: 4px;
`

export const BudgetContainer = styled.div`
  display: grid;
  gap: 8px;
`

export const BudgetSliderWrapper = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr;
  gap: 16px;
`
export const BudgetSliderSideContainer = styled.div`
  display: grid;
  gap: 24px;
`

export const SectionTitle = styled.div`
  color: ${colors.mainText};
  font-size: ${variables.fontSize14};
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
`

export const StyledPercentage = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${colors.mainText};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const CircularProgressBarWrapper = styled.div`
  .ant-progress-inner {
    width: 80px !important;
    height: 80px !important;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  text-align: center;
  margin: 0 auto;
  & svg {
    width: 80px;
    height: 80px;
  }
  @media ${device.mobileMin} {
    grid-row: span 2 / span 2;
    align-self: center;
  }
`

export const MatchingBlockCuratorsReach = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border: 1px solid ${colors.greyStroke};
  border-radius: 4px;
`

export const MatchingBlockPotentialReach = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  border: 1px solid ${colors.greyStroke};
  border-radius: 4px;
`

export const MatchingBlockHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 21px;
  margin: 0;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`

export const MatchingBlockHeaderPotentialReach = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: ${variables.fontSize14};
  line-height: 21px;
  font-weight: ${variables.fontWeight400};
  color: ${colors.mainText};
`

export const MatchingBlockValue = styled.div``

export const MatchingBlockValueMain = styled.span`
  font-size: 20px;
  line-height: 150%;
  font-weight: ${variables.fontWeight600};
  color: ${colors.mainText};
`
interface Props {
  $hasActivePlan?: boolean
}

export const SliderContainer = styled.div<Props>`
  height: ${(props: Props) => (props.$hasActivePlan ? '343px' : '298px')};
  display: grid;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 8px;
  margin-bottom: 16px;
  .ant-slider {
    padding-inline: 0px;
  }
`

export const BudgetInput = styled.input`
  width: 100%;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${colors.mainText};
  border: 1px solid ${colors.greyStroke};
  border-radius: 8px;
  outline: none;
  font-size: ${variables.fontSize18};
  line-height: 150%;
`
export const Blocks = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const LoadingSVGWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
