import styled, { css } from 'styled-components'

import { variables, colors } from 'src/Styled/variables'

interface Props {
  withborder?: string
  withSearchIcon?: boolean
  show?: boolean
}

export const CommonInputWrapper = styled.div<Props>`
  width: 100%;

  ${({ show }) =>
    !show
      ? css`
          display: grid;
          gap: 16px;
          grid-template-columns: 16px 1fr;
          align-items: center;
          background-color: white;
          border-radius: 40px;
          padding-left: 24px;
          padding-right: 24px;
          height: 48px;
          border: 1px solid ${colors.greyStroke} !important;
        `
      : null}

  .ant-input-outlined {
    outline: none !important;
    border: none !important;
    height: 46px;
    padding: 0;
  }

  .ant-input-affix-wrapper.ant-input-affix-wrapper-focused.css-dev-only-do-not-override-p8b6i.ant-input-outlined {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .ant-input {
    outline: none !important;
    box-shadow: none !important;
    height: 46px;
    font-size: ${variables.fontSize14};
    padding: 16px 16px 16px 0px;
    width: 100%;
    position: relative;
    background-color: ${colors.white};
    line-height: 21px;
    color: #999999;
    border: none !important;

    &:focus {
      background-color: ${colors.white};
      outline: none;
      color: ${colors.mainText};
      box-shadow: none;
    }

    &:active {
      background-color: ${colors.white};
      outline: none;
      color: ${colors.mainText};
    }

    @media (max-width: 576px) {
      padding: 0;
      margin: 0;

      border: ${(props) =>
        props.withborder === 'false'
          ? `1px solid ${colors.greyStroke}`
          : 'none !important'};
    }
  }
`
export const SearchIconWrapper = styled.div`
  z-index: 2;
`
